<template>
	<div style="flex: 1;">
		<!-- 手机移动端 -->
		<div v-if="theme.is_mobile && theme.width < 750" style="background-color: #f5f5f5; padding: 10px;">
			<div style="background-color: #fff; padding: 15px; margin-bottom: 15px; position: relative; border-radius: 8px;">
				<div style="display: flex; justify-content: space-between;">
					<div style="position: relative;">
						<div id="performance_objective" style="width: 170px; height: 260px;"></div>
						<el-button type="text" size="mini" style="position: absolute; bottom: 0;" icon="el-icon-s-flag" :disabled="!$utils.index($api.URI_PERFORMANCES_OBJECTIVES)" @click="create_dialog = true;" v-if="!data.objective">设置目标</el-button>
					</div>
					<div style="width: 160px; display: flex; justify-content: space-between; flex-direction: column;">
						<div class="info-label" v-for="(profile, p) in data.performance_objectives" :key="p" @click="onRoute(profile.path)">
							<h5>{{profile.name}} <label :class="{increase: profile.increase}">{{profile.increase ? '+' : '-'}}{{profile.percent}}%</label></h5>
							<h3> {{profile.current}} <span>元</span></h3>
							<p>{{profile.type | chainType}} {{profile.previous}} 元</p>
						</div>
					</div>
				</div>
			</div>
			<div style="background-color: #fff; padding: 10px; margin-bottom: 15px; position: relative; border-radius: 8px;">
				<div style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 20px;">
					<h3 style="color: #444;">销售简报</h3>
					<div>
						<el-radio-group size="mini" v-model="params.type" @change="getHome()">
							<el-radio-button :label="1">个人</el-radio-button>
							<el-radio-button :label="2">团队</el-radio-button>
						</el-radio-group>
						<el-select size="mini" style="width: 72px; margin-left: 10px;" v-model="params.cycle" @change="getHome()" placeholder="周期">
							<el-option value="day" label="今天"></el-option>
							<el-option value="week" label="本周"></el-option>
							<el-option value="month" label="本月"></el-option>
							<el-option value="year" label="今年"></el-option>
						</el-select>
					</div>
				</div>
				<div style="display: grid; grid-template-rows: 50%; grid-template-columns: 50% 50%;">
					<div class="card-label m" v-for="(profile, p) in data.sales_presentations" :key="p" @click="onRoute(profile.path)">
						<h5>{{profile.name}} <label :class="{increase: profile.increase}">{{profile.increase ? '+' : '-'}}{{profile.percent}}%</label></h5>
						<h3>{{profile.current}} <span>{{profile.unit}}</span></h3>
						<p>{{profile.type | chainType}} {{profile.previous}} {{profile.unit}}</p>
					</div>
				</div>
				<div style="position: absolute; top: 20px; right: 20px;">
				</div>
			</div>
			<div style="background-color: #fff; padding: 5px 0 20px; margin-bottom: 15px; position: relative; border-radius: 8px;">
				<div>
					<el-divider content-position="left">合同提醒</el-divider>
					<div style="display: grid; padding: 0 5px; grid-template-rows: 50%; grid-template-columns: 50% 50%;">
						<div class="tips-label m">
							<h5>待审核</h5>
							<p>{{data.reminds.contracts.stay.count}} 份 / {{data.reminds.contracts.stay.total/100}}元</p>
						</div>
						<div class="tips-label m">
							<h5>审核中</h5>
							<p>{{data.reminds.contracts.checking.count}} 份 / {{data.reminds.contracts.checking.total/100}}元</p>
						</div>
						<div class="tips-label m">
							<h5>已过期</h5>
							<p>{{data.reminds.contracts.expired}} 份</p>
						</div>
						<div class="tips-label m">
							<h5>即将过期(近15天)</h5>
							<p>{{data.reminds.contracts.expire}} 份</p>
						</div>
					</div>
				</div>
				<div>
					<el-divider content-position="left">回款提醒</el-divider>
					<div style="display: grid; padding: 0 5px; grid-template-columns: 50% 50%;">
						<div class="tips-label m">
							<h5>待审核(含待支付)</h5>
							<p>{{data.reminds.client_fees.stay.count}} 份 / {{data.reminds.client_fees.stay.total/100}}元</p>
						</div>
						<div class="tips-label m">
							<h5>审核中</h5>
							<p>{{data.reminds.client_fees.checking.count}} 份 / {{data.reminds.client_fees.checking.total/100}}元</p>
						</div>
					</div>
				</div>
				<div>
					<el-divider content-position="left">客户提醒</el-divider>
					<div style="display: grid; padding: 0 5px; grid-template-columns: 50% 50%;">
						<div class="tips-label m">
							<h5>待跟进</h5>
							<p>{{data.reminds.clients.stay}} 个</p>
						</div>
						<div class="tips-label m">
							<h5>未跟进(超过7天)</h5>
							<p>{{data.reminds.clients.not}} 个</p>
						</div>
					</div>
				</div>
			</div>
			<div style="background-color: #fff; padding: 15px 0; margin-bottom: 15px; position: relative; border-radius: 8px;">
				<div id="sales_performance" style="width: 100%; height: 260px;"></div>
				<div style="position: absolute; top: 20px; right: 20px;">
					<div v-if="theme.is_mobile">
						<i class="el-icon-setting"></i>
					</div>
					<el-date-picker size="mini" type="daterange" v-model="params.performance" :picker-options="$config.DATE_PICKER" @change="getHome()" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" v-else></el-date-picker>
				</div>
			</div>
			<div style="background-color: #fff; padding: 15px 0; position: relative; border-radius: 8px;">
				<div id="sales_trend" style="width: 100%; height: 260px;"></div>
				<div style="position: absolute; top: 20px; right: 20px;">
					<div v-if="theme.is_mobile">
						<i class="el-icon-setting"></i>
					</div>
					<el-date-picker size="mini" type="daterange" v-model="params.trend" :picker-options="$config.DATE_PICKER" @change="getHome()" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" v-else></el-date-picker>
				</div>	
			</div>
			<div style="color: #666; font-size: 12px; text-align: center; line-height: 40px;">CopyRight 2019 - {{year}} {{$config.COMPANY}} 版权所有</div>
		</div>
		<!-- 电脑端 -->
		<div class="pc-main" v-else>
			<template v-if="admin_user.shop_id">
				<div style="display: flex; justify-content: space-between;">
					<div>
						<div style="display: flex; justify-content: space-between;">
							<el-card>
								<div style="display: flex; justify-content: space-between;">
									<div style="position: relative;">
										<div id="performance_objective" style="width: 240px; height: 260px;"></div>
										<el-button type="text" size="mini" style="position: absolute; bottom: 0;" icon="el-icon-s-flag" :disabled="!$utils.index($api.URI_PERFORMANCES_OBJECTIVES)" @click="create_dialog = true;" v-if="!data.objective">设置目标</el-button>
									</div>
									<div style="width: 160px; display: flex; justify-content: space-between; flex-direction: column;">
										<div class="info-label" v-for="(profile, p) in data.performance_objectives" :key="p" @click="onRoute(profile.path)">
											<h5>{{profile.name}} <label :class="{increase: profile.increase}">{{profile.increase ? '+' : '-'}}{{profile.percent}}%</label></h5>
											<h3> {{profile.current}} <span>元</span></h3>
											<p>{{profile.type | chainType}} {{profile.previous}} 元</p>
										</div>
									</div>
								</div>
							</el-card>
							<el-card style="width: 360px; margin-left: 15px; position: relative;">
								<div style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 20px;">
									<h3 style="color: #444;">销售简报</h3>
									<div>
										<el-radio-group size="mini" v-model="params.type" @change="getHome()">
											<el-radio-button :label="1">个人</el-radio-button>
											<el-radio-button :label="2">团队</el-radio-button>
										</el-radio-group>
										<el-select size="mini" style="width: 72px; margin-left: 10px;" v-model="params.cycle" @change="getHome()" placeholder="周期">
											<el-option value="day" label="今天"></el-option>
											<el-option value="week" label="本周"></el-option>
											<el-option value="month" label="本月"></el-option>
											<el-option value="year" label="今年"></el-option>
										</el-select>
									</div>
								</div>
								<div style="margin: 0 -11px -20px;">
									<div class="card-label" v-for="(profile, p) in data.sales_presentations" :key="p" @click="onRoute(profile.path)">
										<h5>{{profile.name}} <label :class="{increase: profile.increase}">{{profile.increase ? '+' : '-'}}{{profile.percent}}%</label></h5>
										<h3>{{profile.current}} <span>{{profile.unit}}</span></h3>
										<p>{{profile.type | chainType}} {{profile.previous}} {{profile.unit}}</p>
									</div>
								</div>
								<div style="position: absolute; top: 20px; right: 20px;">
								</div>
							</el-card>
						</div>
					</div>
					<el-card style="flex: 1; margin-left: 15px; position: relative;">
						<div id="sales_performance" style="width: 100%; height: 260px;"></div>
						<div style="position: absolute; top: 20px; right: 20px;">
							<el-date-picker size="mini" type="daterange" v-model="params.performance" :picker-options="$config.DATE_PICKER" @change="getHome()" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
						</div>
					</el-card>
				</div>
				<div style="display: flex; justify-content: space-between; margin-top: 15px;">
					<el-card style="flex: 1; position: relative;">
						<div id="sales_trend" style="width: 100%; height: 260px;"></div>
						<div style="position: absolute; top: 20px; right: 20px;">
							<el-date-picker size="mini" type="daterange" v-model="params.trend" :picker-options="$config.DATE_PICKER" @change="getHome()" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
						</div>	
					</el-card>
					<div style="margin-left: 15px;">
						<el-card :body-style="{padding: '0 10px', maxWidth: '790px'}">
							<div>
								<el-divider content-position="left">合同提醒</el-divider>
								<div style="display: flex; padding-bottom: 15px;">
									<div class="tips-label">
										<h5>待审核</h5>
										<p>{{data.reminds.contracts.stay.count}} 份 / {{data.reminds.contracts.stay.total/100}}元</p>
									</div>
									<div class="tips-label">
										<h5>审核中</h5>
										<p>{{data.reminds.contracts.checking.count}} 份 / {{data.reminds.contracts.checking.total/100}}元</p>
									</div>
									<div class="tips-label">
										<h5>已过期</h5>
										<p>{{data.reminds.contracts.expired}} 份</p>
									</div>
									<div class="tips-label">
										<h5>即将过期(近15天)</h5>
										<p>{{data.reminds.contracts.expire}} 份</p>
									</div>
								</div>
							</div>
							<div style="display: flex;">
								<div>
									<el-divider content-position="left">回款提醒</el-divider>
									<div style="display: flex; padding-bottom: 30px;">
										<div class="tips-label">
											<h5>待审核(含待支付)</h5>
											<p>{{data.reminds.client_fees.stay.count}} 份 / {{data.reminds.client_fees.stay.total/100}}元</p>
										</div>
										<div class="tips-label">
											<h5>审核中</h5>
											<p>{{data.reminds.client_fees.checking.count}} 份 / {{data.reminds.client_fees.checking.total/100}}元</p>
										</div>
									</div>
								</div>
								<div>
									<el-divider content-position="left">客户提醒</el-divider>
									<div style="display: flex; padding-bottom: 30px;">
										<div class="tips-label">
											<h5>待跟进</h5>
											<p>{{data.reminds.clients.stay}} 个</p>
										</div>
										<div class="tips-label">
											<h5>未跟进(超过7天)</h5>
											<p>{{data.reminds.clients.not}} 个</p>
										</div>
									</div>
								</div>
							</div>
						</el-card>
					</div>
				</div>
			</template>	
			<template v-else>	
				<el-card v-if="data.system_info">
					<div slot="header" style="display: flex; justify-content: space-between;">
						<div>服务器监控：{{data.system_info.system}}</div>
						<div>已不间断运行：{{data.system_info.time}}</div>
					</div>
					<div style="margin-right: -10px; margin-bottom: 10px;">
						<div class="system-label" v-if="data.system_info.load">
							<h5>负载状态 <i class="el-icon-question"></i></h5>
							<el-progress type="circle" :percentage="parseInt((data.system_info.load.one/data.system_info.load.max)*100)" :color="colors" :stroke-width="9"></el-progress>
							<p>运行流畅</p>
						</div>
						<div class="system-label" v-if="data.system_info.cpu">
							<h5>CPU使用率</h5>
							<el-progress type="circle" :percentage="data.system_info.cpu[0]" :color="colors" :stroke-width="9"></el-progress>
							<p>{{data.system_info.cpu[1]}}核心</p>
						</div>
						<div class="system-label" v-if="data.system_info.mem">
							<h5>内存使用率</h5>
							<el-progress type="circle" :percentage="parseFloat(((data.system_info.mem.memRealUsed/data.system_info.mem.memTotal)*100).toFixed(2))" :color="colors" :stroke-width="9"></el-progress>
							<p>{{data.system_info.mem.memRealUsed}}/{{data.system_info.mem.memTotal}}(MB)</p>
						</div>
						<template v-if="data.system_info.disk">
							<div class="system-label" v-for="(disk, d) in data.system_info.disk" :key="d">
								<h5>{{disk.path}}</h5>
								<el-progress type="circle" :percentage="parseFloat(disk.size[3])" :color="colors" :stroke-width="9"></el-progress>
								<p>{{disk.size[1]}}/{{disk.size[0]}}</p>
							</div>
						</template>
					</div>
					<el-alert :title="'云服务器实例由 '+data.ecs.console+' 提供，到期时间：'+data.ecs.expire_at" type="warning" show-icon></el-alert>
				</el-card>
			</template>
		</div>
		<el-dialog width="380px" title="添加目标" :visible.sync="create_dialog" @closed="objective = {}">
			<el-form ref="create_objective" label-width="80px" :model="objective" :size="theme.size" status-icon>
				<el-form-item label="业务专员" prop="employee_id" :rules="[{ required: true, message: '请选择业务员', trigger: ['blur', 'change'] }]">
					<el-select v-model="objective.employee_id" placeholder="业务员。可搜索呀" filterable clearable>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="金额" prop="money" :rules="[{ required: true, message: '必须填写金额', trigger: 'blur' }, { type: 'number', min: 0.01, message: '金额最小为1分', trigger: 'blur' }]">
					<el-input-number v-model.number="objective.money" placeholder="请输入金额" :precision="2" :step="0.01" :min="0.01"></el-input-number> 元
				</el-form-item>
				<el-form-item label="周期">
					<el-switch v-model="objective.next" active-value="1" inactive-value="0" active-text="下月" inactive-text="本月">
					</el-switch>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" :disabled="!$utils.create($api.URI_PERFORMANCES_OBJECTIVES)" @click="submitForm('create_objective')">添加保存</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<van-number-keyboard safe-area-inset-bottom ></van-number-keyboard>
	</div>
</template>

<style>
	.btn {
		cursor: pointer;
		display: inline-block;
		margin: 0 10px 10px 0;
	}
	.system-label {
		line-height: 2; 
		text-align: center;
		margin-right: 10px;
		display: inline-block;
	}
	.info-label {
		width: 100%;
		cursor: pointer;
		line-height: 1.3;
		padding:8px 10px;
		border-radius: 8px;
		background-color: #f1f1f1;
	}
	.info-label h3 {
		font-size: 20px;
	}
	.info-label h5 {
		display: flex;
		font-size: 14px;
		align-items: flex-end;
		justify-content: space-between;
	}
	.info-label p {
		font-size: 12px;
		font-weight: bold;
	}
	.info-label span {
		font-size: 12px;
	}
	.info-label label {
		color: #fff;
		padding: 0 8px;
		font-size: 12px;
		border-radius: 8px;
		background-color: rgba(255, 100, 100, 1);
	}
	.info-label label.increase {
		color: #fff;
		padding: 0 8px;
		border-radius: 8px;
		background-color: rgba(100, 200, 100, 1);
	}
	.tips-label {
		width: 170px;
		cursor: pointer;
		margin: 0 10px;
		padding: 15px;
		line-height: 1.5;
		background-color: #f1f1f1;
	}
	.tips-label.m {
		width: 92%;
		margin: 0 auto 15px;
	}
	.tips-label h5 {
		font-size: 14px;
	}
	.tips-label p {
		font-size: 16px;
	}
	.tips-label span {
		font-size: 12px;
	}
	.card-label {
		width: 150px;
		padding: 10px;
		cursor: pointer;
		line-height: 1.5;
		margin: 0 10px 20px;
		display: inline-block;
		background-color: #f1f1f1;
	}
	.card-label.m {
		width: 92%;
		margin: 0 auto 15px;
	}
	.card-label h3 {
		font-size: 24px;
	}
	.card-label h5 {
		font-size: 14px;
	}
	.card-label p {
		font-size: 12px;
		font-weight: bold;
	}
	.card-label span {
		font-size: 12px;
	}
	.card-label label {
		color: #fff;
		padding: 0 8px;
		font-size: 12px;
		border-radius: 8px;
		background-color: rgba(255, 100, 100, 1);
	}
	.card-label label.increase {
		color: #fff;
		padding: 0 8px;
		border-radius: 8px;
		background-color: rgba(100, 200, 100, 1);
	}
	.input-new-route {
		margin-left: 6px;
	}
</style>

<script>
	import { mapState, mapActions } from 'vuex';
	import * as echarts from 'echarts';

	export default {
		computed: {
			...mapState(['hasLogin', 'history_routes', 'admin_permissions', 'theme', 'admin_user']),
			year () {
				var d = new Date();
				return d.getFullYear();
			},
			height () {
				return this.theme.height - 180;
			}
		},
		filters: {
			chainType (v) {
				var t = "昨天";
				if (v == 'week') {
					t = "上周";
				}
				if (v == 'month') {
					t = "上月";
				}
				if (v == 'year') {
					t = "去年";
				}
				return t;
			}
		},
		methods: {
			...mapActions(['logout']),
			handleCommand(cmd) {
				if (cmd == 'logout') {
					this.$confirm('此操作注销退出系统, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$message({
							type: 'success',
							message: '注销成功！正在退出系统...'
						});
					});
				}
			},
			async querySearchAsync (q, cb) {
				if (!q) return false;
				let routes = this.admin_permissions.filter(r => (r.name.toLowerCase().indexOf(q.toLowerCase()) === 0 && r.action === 'default'));
				let results = routes.map((c) => ({value: c.name, path: c.path}));
				cb(results);
			},
			async getHome () {
				const res = await this.$http.get(this.$api.URI_HOME, {params: this.params});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.data = result;
				this.employees = result.employees;
				if (result.performance_objective) {
					this.initObjective(result.performance_objective.personage, result.performance_objective.team);
				}
				if (result.sales_performance) {
					this.initPerformance(result.sales_performance);
				}
				if (result.sales_trend) {
					this.initTrend(result.sales_trend);
				}
			},
			showRouteInput () {
				this.add_route = true;
				this.$nextTick(() => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},
			blurInput () {
				if (!this.search_route) {
					this.add_route = false;
				}
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'create_objective':
							this.submitPerformanceObjectives(this.objective);
						break;
					}
				})
			},
			async submitPerformanceObjectives (data) {
				const res = await this.$http.post(this.$api.URI_PERFORMANCES_OBJECTIVES, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.create_dialog = false;
					}
				});
			},
			onRoute (p) {
				const { path } = this.$route;
				if (p === path) return false;
				this.$router.push({path: p});
			},
			removeHistory (p) {
				this.$confirm('确定要删除【'+p.name+'】吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.$store.commit('setHistoryRoutes', this.history_routes.filter(history => history.path !== p.path));
				});
			},
			initEchart (id, option) {
				option && echarts.init(document.getElementById(id)).setOption(option);
			},
			initObjective (p = 0, t = 0) {
				const gaugeData = [
					{
						value: t,
						name: '团队目标',
						title: {
							offsetCenter: ['0%', '-45%']
						},
						detail: {
							valueAnimation: true,
							offsetCenter: ['0%', '-20%']
						}
					}, {
						value: p,
						name: '个人目标',
						title: {
							offsetCenter: ['0%', '10%']
						},
						detail: {
							valueAnimation: true,
							offsetCenter: ['0%', '35%']
						}
					}
				];
				const { is_mobile } = this.theme;
				const option = {
					title: {
						text: '绩效目标'
					},
					series: [
						{
							type: 'gauge',
							startAngle: 90,
							endAngle: -360,
							pointer: {
								show: false
							},
							progress: {
								show: true,
								overlap: false,
								roundCap: true,
								clip: false
							},
							axisLine: {
								lineStyle: {
									width: is_mobile ? 20 : 30
								}
							},
							splitLine: {
								show: false,
								distance: 0,
								length: 10
							},
							axisTick: {
								show: false
							},
							axisLabel: {
								show: false,
								distance: 50
							},
							data: gaugeData,
							title: {
								fontSize: 12
							},
							detail: {
								width: is_mobile ? 30 : 50,
								height: is_mobile ? 6 : 12,
								fontSize: 12,
								color: 'inherit',
								borderColor: 'inherit',
								borderRadius: 20,
								borderWidth: 1,
								formatter: '{value}%'
							}
						}
					]
				};
				this.initEchart('performance_objective', option);
			},
			initTrend (source) {
				const option = {
					title: {
						text: '销售趋势'
					},
					tooltip: {
						trigger: 'axis'
					},
					grid: {
						left: 30,
						right: 30,
						bottom: 60,
					},
					legend: {
						bottom: 0
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
					},
					yAxis: {
						type: 'value'
					},
					series: source.map((d) => {
						return {
							...d,
							areaStyle: d.areaStyle ? {
								...d.areaStyle,
								color: d.areaStyle.color ? new echarts.graphic.LinearGradient(0, 0, 0, 1, d.areaStyle.color) : ''
							} : {}
						}
					})
				};
				this.initEchart('sales_trend', option);
			},
			initPerformance (source) {
				const option = {
					title: {
						text: '销售金额'
					},
					tooltip: {
						trigger: 'axis'
					},
					grid: {
						left: 40,
						right: 40,
						bottom: 60,
						containLabel: false
					},
					legend: {
						bottom: 0
					},
					dataset: {
						dimensions: source.length ? source[0].keys : [],
						source
					},
					xAxis: {
						type: 'category',
						boundaryGap: false
					},
					yAxis: {
						type: 'value'
					},
					series: [
						{ type: 'line', showSymbol: false },
						{ type: 'line', showSymbol: false },
						{ type: 'line', showSymbol: false }
					]
				};

				this.initEchart('sales_performance', option);
			}
		},
		data() {
			const t = new Date();
			t.setTime(t.getTime() - 3600 * 1000 * 24 * 365);
			return {
				data: {},
				employees: [],
				params: {
					type: 1,
					cycle: 'day',
					performance: [t, new Date()],
					trend: [t, new Date()],
				},
				objective: {},
				search_route: '',
				add_route: false,
				create_dialog: false,
				objective_drawer: false,
				timer: null,
				colors: [
					{color: '#1989fa', percentage: 60},
					{color: '#f90', percentage: 90},
					{color: '#f00', percentage: 100}
				]
			}
		},
		async mounted () {
			this.initObjective(0, 0);
			this.initTrend([
				{
					name: '客户',
					type: 'line',
					smooth: true,
					showSymbol: false,
					areaStyle: {
						opacity: 0.8,
						color: [
							{
								offset: 0,
								color: 'rgba(55, 162, 255, 0.3)'
							}, {
								offset: 1,
								color: 'rgba(116, 21, 219, 0)'
							}
						]
					},
					data: [0]
				}, {
					name: '跟进',
					type: 'line',
					smooth: true,
					showSymbol: false,
					areaStyle: {
						opacity: 0.8,
						color: [
							{
								offset: 0,
								color: 'rgba(128, 255, 165, 0.3)'
							}, {
								offset: 1,
								color: 'rgba(1, 191, 236, 0)'
							}
						]
					},
					data: [0]
				}, {
					name: '商机',
					type: 'line',
					smooth: true,
					showSymbol: false,
					areaStyle: {
						opacity: 0.8,
						color: [
							{
								offset: 0,
								color: 'rgba(255, 191, 0, 0.3)'
							}, {
								offset: 1,
								color: 'rgba(224, 62, 76, 0)'
							}
						]
					},
					data: [0]
				}, {
					name: '合同',
					type: 'line',
					smooth: true,
					showSymbol: false,
					areaStyle: {
						opacity: 0.8,
						color: [
							{
								offset: 0,
								color: 'rgba(255, 0, 135, 0.3)'
							}, {
								offset: 1,
								color: 'rgba(255, 0, 135, 0)'
							}
						]
					},
					data: [0]
				}
			]);
			this.initPerformance([
				{ product: '周一', '商机': 0, '合同': 0, '回款': 0 },
				{ product: '周二', '商机': 0, '合同': 0, '回款': 0 },
				{ product: '周三', '商机': 0, '合同': 0, '回款': 0 },
				{ product: '周四', '商机': 0, '合同': 0, '回款': 0 },
				{ product: '周五', '商机': 0, '合同': 0, '回款': 0 },
				{ product: '周六', '商机': 0, '合同': 0, '回款': 0 },
				{ product: '周日', '商机': 0, '合同': 0, '回款': 0 }
			]);
			// await window.AMapLoader.load({
			// 	"key": "08aa15adc7d41a9fe82ecb2aa53879e4",              // 申请好的Web端开发者Key，首次调用 load 时必填
			// 	"version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
			// 	"plugins": ['AMap.PolygonEditor', 'AMap.PlaceSearch', 'AMap.AutoComplete'],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
			// 	"AMapUI": {             // 是否加载 AMapUI，缺省不加载
			// 		"version": '1.1',   // AMapUI 版本
			// 		"plugins":['overlay/SimpleMarker'],       // 需要加载的 AMapUI ui插件
			// 	},
			// 	"Loca":{                // 是否加载 Loca， 缺省不加载
			// 		"version": '2.0'  // Loca 版本
			// 	},
			// });
			// this.map = new window.AMap.Map('container', {center: [114.05956, 22.54286], zoom: 10.8});
			// this.map.setMapStyle('amap://styles/light');
		},
		created () {
			this.getHome();
			this.timer = setInterval(() => {this.getHome()}, 4000);
		},
		beforeDestroy() {
			clearInterval(this.timer);
			this.timer = null;
		}
	};
</script>
